import React from 'react';

export default function About(): React.ReactElement {
	return (
		<div className='container about'>
			<div className='row'>
				<div className='content-page'>
					<img
						alt='about'
						src='../img/logo.png'
						style={{ width: '80%', height: '80%' }}
					/>
				</div>
				<div className='content-page'>
					<h2 style={{ fontSize: '40px' }}>ABOUT</h2>
					<p
						style={{
							textAlign: 'left',
							marginLeft: '15px',
							marginRight: '15px',
						}}
					>
						We are a company focused on the calculation of structures, and we
						take care of the complete structural project from the initial
						analysis to the end coordination and supervision.
						<br />
						<br />
						We started our journey in 2022 and we already give service to
						numerous clients, from individuals to large companies, mainly in the
						national market but continuosuly growing to international projects.
						<br />
						<br />
						With a young and talented team based in Barcelona, we have all the
						ingredients to make your next project successful.
					</p>
					<p style={{ fontWeight: 'bold', fontStyle: 'oblique' }}>
						Complex projects, brilliant ideas.
					</p>
				</div>
			</div>
		</div>
	);
}

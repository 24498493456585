import React from 'react';
import { Link } from 'react-scroll';

export default function Bar(): React.ReactElement {
	return (
		<ul className='bar'>
			<li className='nav-item'>
				<Link
					activeClass='active'
					spy={true}
					smooth={true}
					offset={-50}
					duration={500}
					to='home'
				>
					Home
				</Link>
			</li>
			<li className='nav-item'>
				<Link
					activeClass='active'
					spy={true}
					smooth={true}
					offset={-50}
					duration={500}
					to='about'
				>
					About
				</Link>
			</li>
			<li className='nav-item'>
				<Link
					activeClass='active'
					spy={true}
					smooth={true}
					offset={-50}
					duration={500}
					to='services'
				>
					Services
				</Link>
			</li>
		</ul>
	);
}

import React from 'react';
import { Link } from 'react-scroll';
import { Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

interface Props {
	onClick(): void;
}

export default function ButtonDrawer(props: Props): React.ReactElement<Props> {
	return (
		<div style={{ width: '5%' }}>
			<Button
				type='primary'
				size='large'
				onClick={props.onClick}
				style={{
					marginInline: 'auto',
					height: '32px',
					float: 'right',
					border: 'none',
					background: 'none',
				}}
				icon={<MenuOutlined />}
				//onClick={showDrawer}
			></Button>
		</div>
	);
}

import React from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';

export default function Logo(): React.ReactElement {
	return (
		<div>
			<h1 className='logo'>
				<Link
					activeClass='active'
					spy={true}
					smooth={true}
					offset={-50}
					duration={500}
					to={''}
				>
					<img
						src={'../img/logo.png'}
						className='nav-logo'
						alt='Logo.'
						onClick={scrollToTop}
					/>
				</Link>
			</h1>
		</div>
	);
}

function scrollToTop(): void {
	scroll.scrollToTop();
}

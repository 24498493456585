import React from 'react';

export default function Home(): React.ReactElement {
	return (
		<header id='home'>
			<video src='/bridgesf.mp4' loop autoPlay muted></video>
			<h1>EPSILON STRUCTURAL ENGINEERING </h1>
			<div className='headeropacity'></div>
		</header>
	);
}

import React from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import Media from './Media';
import Contact from './Contact';
import Logo from '../NavBar/Logo';

export default function Footer(): React.ReactElement {
	return (
		<footer>
			<div className='row'>
				<Logo />
				<Contact />
				<Media />
			</div>
		</footer>
	);
}

import React from 'react';
import { Avatar, Card, Carousel } from 'antd';
import { isTemplateSpan } from 'typescript';

interface Props {
	title: string;
	description: string;
	imgSrc: string[];
	list?: React.ReactElement;
}

export default function CardService(props: Props): React.ReactElement<Props> {
	return (
		<div className='card-service'>
			<Card
				cover={
					<Carousel autoplay>
						{props.imgSrc.map((image: string) => {
							return (
								<div className='image-carousel'>
									<img
										alt=''
										src={image}
										style={{
											width: '100%',
											height: '100%',
											borderRadius: '8px',
										}}
									/>
								</div>
							);
						})}
					</Carousel>
				}
				actions={[]}
			>
				<Card.Meta
					//avatar={<Avatar src='https://joeschmoe.io/api/v1/random' />}
					title={props.title}
					description={props.description}
				/>
				{props.list}
			</Card>
		</div>
	);
}

import './App.css';
import React from 'react';
import NavBar from './components/NavBar/NavBar';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services/Services';
import Footer from './components/Footer/Footer';

function App() {
	return (
		<div className='App'>
			<React.Fragment>
				<NavBar />
				<Home />
				<About />
				<Services />
				<Footer />
			</React.Fragment>
		</div>
	);
}

export default App;

import React from 'react';
import CardService from './CardService';

export default function Services(): React.ReactElement {
	return (
		<div className='container services'>
			<div className='row'>
				<div className='content-page-services'>
					<h2 style={{ fontSize: '40px' }}>SERVICES</h2>
					<p>
						We have extended professional experience in calculations of a wide
						range of structural elements for several sectors.
					</p>
				</div>
			</div>
			<div className='card-row' style={{ justifyContent: 'center' }}>
				<CardService
					title='Calculation of residential buildings'
					description='Structure analysis, design, calculation and dimensioning for residential buildings.'
					imgSrc={[
						'./img/services/residential/5.png',
						'./img/services/residential/6.png',
						'./img/services/residential/7.png',
						'./img/services/residential/8.png',
					]}
					list={
						<div className='card-service-list'>
							<li style={{ textAlign: 'left' }}>
								Reinforced concrete insitu and prefabricated
							</li>
							<li style={{ textAlign: 'left' }}>
								Mixed structures (metallic and concrete)
							</li>
							<li style={{ textAlign: 'left' }}>Metallic structures</li>
							<li style={{ textAlign: 'left' }}>Wood structures</li>
						</div>
					}
				/>

				<CardService
					title='Industrial structures and public constructions'
					description='Structure analysis, design, calculation and dimensioning for industrial and public constructions.'
					imgSrc={[
						'./img/services/industrial/aa.PNG',
						'./img/services/industrial/ee.PNG',
						'./img/services/industrial/cc.PNG',
						'./img/services/industrial/pp.PNG',
					]}
					list={
						<div className='card-service-list'>
							<li style={{ textAlign: 'left' }}>
								Metallic structures for industrial equipment
							</li>
							<li style={{ textAlign: 'left' }}>
								Reinforced concrete structures
							</li>
							<li style={{ textAlign: 'left' }}>Sewage pumping stations</li>
						</div>
					}
				/>
				<CardService
					title='Calculation of advanced structures'
					description='Structural elements of high complexity and singular structures.'
					imgSrc={[
						'./img/services/advanced/skycamstruct.png',
						'./img/services/advanced/campnou.jpg',
						'./img/services/advanced/10.png',
						'./img/services/advanced/9.png',
					]}
					list={
						<div className='card-service-list'>
							<li style={{ textAlign: 'left' }}>
								Modelization with Finite Element Method (FEM)
							</li>
							<li style={{ textAlign: 'left' }}>
								Structures of composite materials
							</li>
							<p style={{ textAlign: 'left' }}>
								<i style={{ fontWeight: 'bold' }}>Featured projects: </i>
								SkyCam LaLiga 1st Professional Football League
							</p>
						</div>
					}
				/>
			</div>
			<div className='card-row' style={{ justifyContent: 'center' }}>
				<CardService
					title='Construction management'
					description='Construction coordination for urbanization projects, building projects and structures installation.'
					imgSrc={[
						'./img/services/dirobra/dirobraosasuna.jpg',
						'./img/services/dirobra/skycam.jpg',
						'./img/services/dirobra/visitaobra.jpg',
						'./img/services/dirobra/estadimirandes.jpg',
					]}
					list={
						<div className='card-service-list'>
							<li style={{ textAlign: 'left' }}>
								Supervision of works, monitoring and control
							</li>
							<li style={{ textAlign: 'left' }}>
								Quality assurance and control on site
							</li>
							<li style={{ textAlign: 'left' }}>
								Health and safety coordination and supervision
							</li>
						</div>
					}
				/>
				<CardService
					title='Analysis of structural pathologies and rehabilitation'
					description='On site evaluation of structural damages'
					imgSrc={[
						'./img/services/patologia/patologiametro.png',
						'./img/services/patologia/1.jpg',
						'./img/services/patologia/vies.jpg',
					]}
					list={
						<div className='card-service-list'>
							<li style={{ textAlign: 'left' }}>
								Experts report of structural damages
							</li>
							<li style={{ textAlign: 'left' }}>Structural auditing</li>
							<p style={{ textAlign: 'left' }}>
								<i style={{ fontWeight: 'bold' }}>Featured projects: </i>
								Barcelona Subway TMB
							</p>
						</div>
					}
				/>
			</div>
		</div>
	);
}

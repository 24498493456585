import React from 'react';
import { LinkedinOutlined, InstagramOutlined } from '@ant-design/icons';

export default function Media(): React.ReactElement {
	function handleLinkedinClick(): void {}
	return (
		<div style={{ alignSelf: 'center' }}>
			<LinkedinOutlined
				style={{
					fontSize: '30px',
					margin: 'auto',
				}}
				onClick={() =>
					window.open(
						'https://es.linkedin.com/company/epsilon-structural-engineering',
						'_blank'
					)
				}
			/>
			<InstagramOutlined
				style={{ fontSize: '30px' }}
				onClick={() =>
					window.open('https://www.instagram.com/epsilonse/', '_blank')
				}
			/>
		</div>
	);
}

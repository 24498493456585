import React from 'react';
import { Link } from 'react-scroll';

interface Props {
	onClick(): void;
}

export default function BarMenu(props: Props): React.ReactElement<Props> {
	return (
		<ul className='bar' style={{ display: 'grid' }}>
			<li className='nav-item' style={{ marginLeft: '0' }}>
				<Link
					activeClass='active'
					onClick={props.onClick}
					spy={true}
					smooth={true}
					offset={-50}
					duration={500}
					to='home'
				>
					Home
				</Link>
			</li>
			<li className='nav-item' style={{ marginLeft: '0' }}>
				<Link
					activeClass='active'
					onClick={props.onClick}
					spy={true}
					smooth={true}
					offset={-50}
					duration={500}
					to='about'
				>
					About
				</Link>
			</li>
			<li className='nav-item' style={{ marginLeft: '0' }}>
				<Link
					activeClass='active'
					onClick={props.onClick}
					spy={true}
					smooth={true}
					offset={-50}
					duration={500}
					to='services'
				>
					Services
				</Link>
			</li>
		</ul>
	);
}

import React from 'react';
import { Link } from 'react-scroll';

export default function Contact(): React.ReactElement {
	return (
		<div>
			<p className='footer-title'>Contact</p>
			<ul>
				<li>
					<Link to='#'>jvendrell.engineering@gmail.com</Link>
				</li>
				<li>
					<Link to='#'>Barcelona, Spain</Link>
				</li>
			</ul>
		</div>
	);
}

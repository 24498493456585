import React, { useLayoutEffect, useState } from 'react';
import Logo from './Logo';
import Bar from './Bar';
import BarMenu from './BarMenu';
import ButtonDrawer from './Button';

export default function NavBar(): React.ReactElement {
	const [visible, setVisible] = useState(false);
	const [width, height] = useWindowSize();
	const changeShowMenu = () => {
		setVisible(!visible);
	};
	document.addEventListener('scroll', function (e) {
		if (window.scrollY > 300) {
			const nav = document.querySelector('.navbar');
			if (nav !== null) {
				nav.classList.add('navopened');
			}
		} else {
			const nav = document.querySelector('.navbar');
			if (nav !== null) {
				nav.classList.remove('navopened');
			}
		}
	});
	return (
		<nav className='navbar' id='navbar'>
			<div className='nav-content' style={{ marginInline: 'auto' }}>
				<React.Fragment>
					<Logo />
					{width > 1000 ? <Bar /> : <div style={{ margin: 'auto' }} />}
					{width < 1000 && <ButtonDrawer onClick={changeShowMenu} />}
				</React.Fragment>
			</div>
			{width < 1000 && visible && (
				<div style={{ width: '100%' }}>
					<BarMenu onClick={changeShowMenu} />
				</div>
			)}
		</nav>
	);
}

function useWindowSize() {
	const [size, setSize] = useState([0, 0]);
	useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);
	return size;
}
